<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo
            :titulo="'Contas a Pagar'"
            @openOffcanvas="openOffcanvas"
          ></topo>
          <base-header type="gradient-success" class="pt-md-3">
            <div class="row">
              <div class="col-3 col-lg-2 col-md-3">
                <label>Código</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Código:"
                  v-model="state.codigo"
                />
              </div>
              <div class="col-9 col-lg-6 col-md-9">
                <label>Nome</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome:"
                  v-model="state.nome_busca"
                />
              </div>
              <div class="col-lg-2">
                <button
                  type="primary"
                  class="btn_busca"
                  @click="buscarContaPorNomeCliente()"
                >
                  Buscar
                </button>
              </div>
              <div class="col-xl-2 col-lg-4 col-md-6 mb-2 d-none d-md-block">
                <router-link to="/adicionar-conta-pagar" class="primary">
                  <button
                    class="btn_conta"
                    style="margin-top: 24px"
                    type="buttons"
                  >
                    Adicionar Nova Conta
                  </button>
                </router-link>
              </div>
            </div>
          </base-header>

          <div>
            <div class="card shadow p-3">
              <div class="row">
                <div class="col">
                  <div class="table-responsive">
                    <table
                      class="table tablesorter table align-items-center table-flush"
                    >
                      {{
                        state.contas
                      }}
                      <thead class="thead-light">
                        <th>Código</th>
                        <th>Fornecedor / Colaborador</th>
                        <th class="th_display">Título</th>
                        <th>Valor Pagar</th>
                        <th>Valor Pago</th>
                        <th></th>
                      </thead>
                      <tbody class="list">
                        <corpo-tabela-conta-pagar
                          v-for="conta in state.contas"
                          :key="conta.id"
                          :conta="conta"
                          @deletarConta="deletarConta"
                          @modalVisualizar="modalVisualizar"
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="state.isOpen" @close="state.isOpen = !state.isOpen">
    <div class="modal_visu_contapagar">
      <h1 class="title_modal">Visualizar Conta a Pagar</h1>
      <div class="row">
        <div class="col-md-3">
          <h3>Func.Cadastrante</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.nome_cadastrante }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Data Lançameno</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.data_cadastro }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Tipo</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.tipo_conta }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Tipo Despesa</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.tipo_despesa }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <h3>Status</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.status }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Título</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.nome }}</p>
          </div>
        </div>
        <div class="col-md-3" v-if="state.tipo_despesa == 'fixa'">
          <h3>Despesa Cadastrada</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.despesa_fixa_id }}</p>
          </div>
        </div>
        <div class="col-md-3" v-if="state.tipo_despesa == 'fixa'">
          <h3>Recorrencia</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.tempo_recorrencia }}</p>
          </div>
        </div>
      </div>
      <div class="row" v-if="state.tipo_conta == 'DESPESA_FORNECEDOR'">
        <div class="col-md-12">
          <h2>Dados Fornecedor</h2>
        </div>
        <div class="col-md-3">
          <h3>Código</h3>
          <p>{{ state.id_fornecedor }}</p>
        </div>
        <div class="col-md-4">
          <h3>Nome</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.nome_fornecedor }}</p>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-12">
          <h2>Dados Colaborador</h2>
        </div>
        <div class="col-md-3">
          <h3>Código</h3>
          <p>{{ state.id_colaborador_recebedor }}</p>
        </div>
        <div class="col-md-4">
          <h3>Nome</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.nome_colaborador }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h2>Documento</h2>
        </div>
        <div class="col-md-3">
          <h3>Tipo</h3>
          <p>{{ state.tipo_documento_id }}</p>
        </div>
        <div class="col-md-3">
          <h3>Doc.Fornecedor</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.n_documento }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Data Emissão</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.data_emissao }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Categoria</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.categoria_contas_pagar }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Valor a Pagar</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.valor_pagar }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Data Vencimento</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.data_vencimento }}</p>
          </div>
        </div>
        <div class="col-md-3" v-if="state.status == 'pago'">
          <h3>Valor Pago</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.valor_pago }}</p>
          </div>
        </div>
        <div class="col-md-3" v-if="state.status == 'pago'">
          <h3>Data Pagamento</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.data_pagamento }}</p>
          </div>
        </div>
        <div class="col-md-3" v-if="state.status == 'pago'">
          <h3>Tipo Pagamento</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.nome_tipo_pagamento }}</p>
          </div>
        </div>
        <div class="col-md-3" v-if="state.status == 'pago'">
          <h3>Func.Pagador</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.nome_pagador }}</p>
          </div>
        </div>
        <div class="col-md-3" v-if="state.status == 'pago'">
          <h3>Local Pagamento</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.tipo_caixa }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>Observação</h3>
          <div class="form-group" style="margin-bottom: 0">
            <p>{{ state.descricao }}</p>
          </div>
        </div>
      </div>
      <div class="btn_direita">
        <button
          class="btn btn_cancelar my-4"
          id="submit-button"
          type="button"
          @click="fecharModal"
        >
          Fechar
        </button>
      </div>
    </div>
  </Modal>
  <OffcanvasComponent ref="offcanvasRef" />
</template>
<script>
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import { onMounted, reactive, onErrorCaptured, ref } from "vue";
import CorpoTabelaContaPagar from "../components/CorpoTabelaContasPagar";
import services from "../services";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
import topo from "../components/topo";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";
export default {
  name: "tables",
  components: {
    CorpoTabelaContaPagar,
    topo,
    BarraLateral,
    Modal,
    OffcanvasComponent,
  },
  setup() {
    const offcanvasRef = ref(null);
    const isVisible = ref(false);

    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const state = reactive({
      //card-stats
      totalPaga: false,
      valorResto: false,
      atrasoValor: false,
      atrasoConta: false,

      isLoading: false,
      contas: [],
      currentClientType: "",
      hasError: false,
      codigo: "",
    });
    onErrorCaptured(handleErrors);

    onMounted(() => {
      fetchContas();
      TotalPagar();
      valorRestante();
      contaAtrasada();
      valorAtrasado();
    });
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function modalVisualizar(id) {
      state.isOpen = true;
      try {
        state.isLoading = true;
        const { data } = await services.contaPagar.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: id,
        });
        state.nome_cadastrante = data.nome_cadastrante;
        state.data_cadastro = data.data_cadastro;
        state.tipo_conta = data.tipo_conta;
        state.tipo_despesa = data.tipo_despesa;
        state.status = data.status;
        state.nome = data.nome;
        state.despesa_fixa_id = data.despesa_fixa_id;
        state.tempo_recorrencia = data.tempo_recorrencia;
        state.id_fornecedor = data.id_fornecedor;
        state.nome_fornecedor = data.nome_fornecedor;
        state.id_colaborador_recebedor = data.id_colaborador_recebedor;
        state.nome_colaborador = data.nome_colaborador;
        state.tipo_documento_id = data.tipo_documento_id;
        state.n_documento = data.n_documento;
        state.data_emissao = data.data_emissao;
        state.categoria_contas_pagar = data.categoria_contas_pagar;
        state.valor_pagar = data.valor_pagar;
        state.data_vencimento = data.data_vencimento;
        state.valor_pago = data.valor_pago;
        state.data_pagamento = data.data_pagamento;
        state.nome_tipo_pagamento = data.nome_tipo_pagamento;
        state.nome_pagador = data.nome_pagador;
        state.tipo_caixa = data.tipo_caixa;
        state.descricao = data.descricao;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function deletarConta(id) {
      if (confirm("Deseja deletar este conta?")) {
        try {
          state.isLoading = true;
          const { data } = await services.contaPagar.deletarConta({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          if (data) {
            fetchContas();
          } else {
            alert("Erro ao deletar conta.");
          }
        } catch (error) {
          handleErrors(error);
        }
      }
    }
    function fecharModal() {
      state.isOpen = false;
    }
    async function fetchContas() {
      try {
        state.isLoading = true;
        const { data } = await services.contaPagar.selecionaPorData({
          type: state.currentClientType,
          token: token,
          empresa_id: empresa_id,
        });
        console.log(data);
        //  console.log(data)
        state.contas = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function buscaContaPagar() {
      if (state.codigo.length > 0) {
        try {
          const { data } = await services.contaPagar.obterPorId({
            token: token,
            empresa_id: empresa_id,
            id: state.codigo,
          });
          var array = [];
          array.push(data);
          state.contas = array;
        } catch (error) {
          handleErrors(error);
        }
      } else {
        try {
          state.isLoading = true;
          const { data } = await services.contaPagar.contasPorNomeCliente({
            token: token,
            empresa_id: empresa_id,
            nome_cliente: state.nome_busca,
          });
          console.log(data);
          //  console.log(data)
          state.contas = data;
          //   state.pagination = data.pagination
          state.isLoading = false;
        } catch (error) {
          handleErrors(error);
        }
      }
    }
    async function TotalPagar() {
      try {
        const TotalPagar = await services.contaPagar.TotalPagar({
          token: token,
          empresa_id: empresa_id,
        });
        state.TotalPagar = TotalPagar.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function contaAtrasada() {
      try {
        const contaAtrasada = await services.contaPagar.contaAtrasada({
          token: token,
          empresa_id: empresa_id,
        });
        state.contaAtrasada = contaAtrasada.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function valorRestante() {
      try {
        const valorRestante = await services.contaPagar.valorRestante({
          token: token,
          empresa_id: empresa_id,
        });
        state.valorRestante = valorRestante.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function valorAtrasado() {
      try {
        const valorAtrasado = await services.contaPagar.valorAtrasado({
          token: token,
          empresa_id: empresa_id,
        });
        state.valorAtrasado = valorAtrasado.data;
      } catch (error) {
        handleErrors(error);
      }
    }
    const openOffcanvas = () => {
      // Estado para controlar a visibilidade do offcanvas
      console.log(isVisible.value);
      //offcanvasRef.value.showOffcanvas();
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      deletarConta,
      state,
      modalVisualizar,
      fecharModal,
      buscaContaPagar,
      openOffcanvas,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>
<style scoped>
@media (min-width: 1000px) and (max-width: 1400px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

.bv {
  font-weight: bold;
  font-size: 18pt;
  color: #162b48;
  margin-bottom: 0;
}

.card .table th {
  padding: 15px !important;
}

.btn_conta {
  transform: none;
  background: var(--Color-secundary, #aede00);
  color: rgb(255, 255, 255);
  border: 1px solid #aede00;
  border-radius: 5px;
  font-size: 9pt;
  padding: 10px;
  font-weight: 700;
  width: 100%;
  margin-top: 26px;
}

.quad {
  padding: 13px;
  gap: 10px;
  width: 50px;
  height: 50px;
  background: #010d1f;
  border-radius: 12px;
}

.title2 {
  color: #000;
  margin-top: 10px;
}

.title1 {
  color: #000;
  margin-top: 10px;
  font-size: 11pt;
  line-height: 1;
}

.num {
  margin-top: -15px;
}

svg {
  color: #aede00;
  width: 24px;
  height: 24px;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

.btn_direita {
  text-align: right;
}

.modal_visu_contapagar {
  padding: 5px;
}

h2 {
  color: #000;
}

h3 {
  color: #000;
}

.title_modal {
  color: #034aa6;
}

input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}

input:focus {
  border-color: #252525;
}

.btn_new_account {
  background-color: rgb(3, 74, 166);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 10px;
  font-size: 9pt;
  padding: 12px;
  font-weight: 700;
  width: 100%;
}

.btn_busca {
  font-size: 10pt;
  border-radius: 5px;
  background-color: rgb(3, 74, 166);
  border: none;
  color: #fff;
  font-weight: 700;
  padding: 10px;
  margin-top: 25px;
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .th_display {
    display: none;
  }

  .btn_new_account {
    width: 90%;
  }
}
</style>
