<template>
  <barra-lateral />
  <div style="background-color: #f8f9fe; min-height: 100vh">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <topo :titulo="'Fornecedores'" @openOffcanvas="openOffcanvas"></topo>
          <div>
            <div class="">
              <div class="row mt-3">
                <div class="col-lg-2 col-sm-2 mb-2 mt-md-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Código"
                    v-model="state.codigo"
                  />
                </div>
                <div class="col-lg-5 col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.nome_busca"
                    placeholder="Nome"
                  />
                </div>
                <div class="col-lg-2 col-sm-2 div_btn_buscar">
                  <button
                    type="button"
                    class="btn btn_busca mb-md-0 mb-2"
                    @click="buscarPorNome()"
                  >
                    Buscar
                  </button>
                </div>
                <div class="col-lg-3 col-12 mt-lg-0 mt-1 div_add_fornecedor">
                  <router-link to="/adicionar-fornecedor" class="primary">
                    <button
                      type="button"
                      id="btn_new_product"
                      class="btn_add_fornecedor"
                    >
                      Adicionar Forncedor
                    </button>
                  </router-link>
                </div>
              </div>
              <div
                class="card shadow p-3"
                :class="type === 'dark' ? 'bg-default' : ''"
                s
              >
                <div class="row">
                  <div class="col">
                    <div class="d-md-none">
                      <div
                        class="quad_phone"
                        v-for="fornecedor in state.fornecedores"
                        :key="fornecedor.id"
                      >
                        <div class="linha">
                          <div class="titulo">Ações</div>
                          <div class="texto">
                            <button
                              class="btn_lupa"
                              title="Visualizar"
                              @click="abrirModal(fornecedor.id)"
                            >
                              <i class="bi bi-search"></i>
                            </button>
                            <button
                              class="btn_x"
                              title="Deletar"
                              @click="deletarFornecedor(fornecedor.id)"
                            >
                              <i class="bi bi-x-lg"></i>
                            </button>
                          </div>
                        </div>
                        <div class="linha2">
                          <div class="titulo">Código</div>
                          <div class="texto">{{ fornecedor.id }}</div>
                        </div>
                        <div class="linha">
                          <div class="titulo">Nome</div>
                          <div class="texto">{{ fornecedor.nome }}</div>
                        </div>
                        <div class="linha2">
                          <div class="titulo">Status</div>
                          <div class="texto">{{ fornecedor.status }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="table-responsive d-none d-md-block">
                      <table
                        class="table tablesorter table align-items-center table-flush"
                      >
                        <thead class="thead-light">
                          <th>Código</th>
                          <th>Nome</th>
                          <th></th>
                          <th>Status</th>
                          <th></th>
                        </thead>
                        <tbody class="list">
                          <CorpoTabelaFonecedor
                            v-for="fornecedor in state.fornecedores"
                            :key="fornecedor.id"
                            :fornecedor="fornecedor"
                            @deletarFornecedor="deletarFornecedor"
                            @abrirModal="abrirModal"
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div>
                  <nav aria-label="">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Anterior">
                          <span class="sr-only">Anterior</span>
                        </a>
                      </li>

                      <li
                        class="page-item"
                        v-for="pagina in state.qtd_paginacao"
                        :key="pagina"
                        :class="{ active: pagina == state.pagina_selecionada }"
                      >
                        <a class="page-link" @click="pagina">{{ pagina }}</a>
                      </li>

                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Próximo">
                          <span class="sr-only">Próximo</span>
                        </a>
                      </li>
                      <li>
                        <select
                          class="form-select"
                          v-model="state.qtd_por_pagina"
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal :open="state.isOpen" @close="state.isOpen = !state.isOpen">
    <h1>Visualizar Fornecedor</h1>
    <h2>Dados Principais</h2>
    <button
      type="primary"
      id="btn_new_product"
      class="btn_edit"
      style="float: right"
      @click="editarModo"
      v-if="state.modo == 'visualizar'"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-pencil-square"
        viewBox="0 0 16 16"
      >
        <path
          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
        />
        <path
          fill-rule="evenodd"
          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
        />
      </svg>
    </button>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Código</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_id"
              readonly
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_id }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Nome</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_nome"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_nome }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <label>Status</label>
        <div v-if="state.modo == 'editar'">
          <div class="form-select">
            <select v-model="state.visualizar_status" class="form-select">
              <option value="ativo">Ativo</option>
              <option value="inativo">Inativo</option>
            </select>
          </div>
        </div>
        <div v-if="state.modo == 'visualizar'">
          <p>{{ state.visualizar_status }}</p>
        </div>
      </div>
      <div class="col-lg-4" v-if="state.tipo_empresa == 'fisica'">
        <div class="form-group">
          <label>Data de Nascimento</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_dataNascimento"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_dataNascimento }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4" v-if="state.tipo_empresa == 'fisica'">
        <div class="form-group">
          <label>Sexo</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_sexo"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_sexo }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2>Documentos</h2>
    <div class="row">
      <div class="col-md-4" v-if="state.tipo_empresa == 'juridica'">
        <div class="form-group">
          <label>CNPJ</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_cnpj"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_cnpj }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="state.tipo_empresa == 'fisica'">
        <div class="form-group">
          <label>RG</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_rg"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_rg }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="state.tipo_empresa == 'fisica'">
        <div class="form-group">
          <label>CPF</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_cpf"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_cpf }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2>Dados para Contato</h2>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>Telefone Principal</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_telefonePrincipal"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_telefonePrincipal }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Telefone Alternativo</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_telefoneAlternativo"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_telefoneAlternativo }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Celular/WhatsApp</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_zap"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_zap }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label>E-mail</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_email"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_email }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2>Endereço</h2>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>País</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_pais"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_pais }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Estado</label>
          <div v-if="state.modo == 'editar'">
            <select
              class="form-select"
              v-model="state.visualizar_estado_id"
              @change="fetchCidades($event.target.value)"
            >
              <select-estados
                v-for="estado in state.estados"
                :key="estado.id"
                :estado="estado"
              />
            </select>
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_estado }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Cidade</label>
          <div v-if="state.modo == 'editar'">
            <select class="form-select" v-model="state.visualizar_cidade_id">
              <option
                v-for="cidade in state.cidades"
                :key="cidade.id"
                :value="cidade.id"
              >
                {{ cidade.nome }}
              </option>
            </select>
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_cidade }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Endereço</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_endereco"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_endereco }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Número</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_num"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_num }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Bairro</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_bairro"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_bairro }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Complemento</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_complemento"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_complemento }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>CEP</label>
          <div v-if="state.modo == 'editar'">
            <input
              type="text"
              class="form-control"
              v-model="state.visualizar_cep"
            />
          </div>
          <div v-if="state.modo == 'visualizar'">
            <p>{{ state.visualizar_cep }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2>Obeservação</h2>
    <div class="row">
      <div class="col-md-12">
        <div v-if="state.modo == 'editar'">
          <textarea class="area_obs" v-model="state.vizualizar_obs"></textarea>
        </div>
        <div v-if="state.modo == 'visualizar'">
          <p>{{ state.vizualizar_obs }}</p>
        </div>
      </div>
    </div>
    <button
      class="btn btn_salvar"
      id="submit-button"
      type="button"
      @click="atualizarForncedor"
      v-if="state.modo == 'editar'"
    >
      Salvar
    </button>
    <button
      class="btn btn_cancelar my-4"
      id="submit-button"
      type="button"
      @click="fecharModal"
      v-if="state.modo == 'visualizar'"
    >
      Fechar
    </button>
  </Modal>
  <OffcanvasComponent ref="offcanvasRef" />
</template>
<script>
import CorpoTabelaFonecedor from "../components/CorpoTabelaFonecedor";
import { onMounted, reactive, ref } from "vue";
import services from "../services";
import { useStorage } from "vue3-storage";
import BarraLateral from "../layout/Sistema/barraLateral";
import Modal from "../components/EstruturaModal/EstruturaModal.vue";
import SelectEstados from "../components/EstadosSelect.vue";
import topo from "../components/topo";
import OffcanvasComponent from "../layout/Sistema/barraLateralMobile";

export default {
  components: {
    topo,
    Modal,
    BarraLateral,
    OffcanvasComponent,
    CorpoTabelaFonecedor,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    var empresa_id = storage.getStorageSync("empresa_id");
    const offcanvasRef = ref(null);
    const isVisible = ref(false);

    const state = reactive({
      //card-stats
      cadastroForn: false,
      ativoForn: false,

      modo: "visualizar",
      estado: 0,
      isLoading: false,
      contas: [],
      codigo: "",
      currentClientType: "",
      hasError: false,
    });
    onMounted(() => {
      buscarTodos();
      fetchEstados();
    });

    function editarModo() {
      if (state.modo == "visualizar") {
        state.modo = "editar";
      } else {
        state.modo = "visualizar";
      }
    }
    async function buscarTodos() {
      try {
        state.isLoading = true;
        const { data } = await services.fornecedores.getAll({
          token: token,
          empresa_id: empresa_id,
        });
        console.log(data);
        //  console.log(data)
        state.fornecedores = data;
        //   state.pagination = data.pagination
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function deletarFornecedor(id) {
      if (confirm("Deseja deletar este fornecedor?")) {
        try {
          state.isLoading = true;
          const { data } = await services.fornecedores.deletarFornecedor({
            token: token,
            empresa_id: empresa_id,
            id,
          });
          if (data.verifica_erro == false) {
            buscarTodos();
          } else {
            alert(
              "Erro ao deletar fornecedor. Verifique se existem contas atreladas a este fornecedor!"
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    async function fetchCidades(estado_id) {
      try {
        state.isLoading = true;
        const { data } = await services.cidades.getAll(estado_id);
        console.log(data);
        state.cidades = data;
        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
    async function abrirModal(id) {
      console.log("aqui");
      try {
        state.isOpen = true;
        const { data } = await services.fornecedores.obterPorId({
          token: token,
          empresa_id: empresa_id,
          id: id,
        });
        state.visualizar_id = data.id;
        state.visualizar_nome = data.nome;
        (state.visualizar_status = data.status),
          (state.visualizar_dataNascimento = data.data_nascimento);
        state.visualizar_sexo = data.sexo;
        state.visualizar_cnpj = data.cnpj;
        state.visualizar_rg = data.rg;
        state.visualizar_cpf = data.cpf;
        state.visualizar_zap = data.telefone;
        state.visualizar_email = data.email;
        state.visualizar_cidade = data.nome_cidade;
        state.visualizar_estado = data.nome_estado;
        state.visualizar_estado_id = data.estado_id;
        fetchCidades(data.estado_id);
        state.visualizar_cidade_id = data.cidade_id;
        state.visualizar_endereco = data.endereco;
        state.visualizar_num = data.numero;
        state.visualizar_bairro = data.bairro;
        state.visualizar_complemento = data.complemento;
        state.visualizar_cep = data.cpf;
        state.visualizar_obs = data.observacao;
        state.tipo_empresa = data.tipo_pessoa;
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    function fecharModal() {
      state.modo = "visualizar";
      state.isOpen = false;
    }
    async function atualizarForncedor() {
      console.log(state.nome);
      try {
        const { data } = await services.fornecedores.alterarModal({
          token: token,
          empresa_id: empresa_id,
          id: state.visualizar_id,
          nome: state.visualizar_nome,
          cpf: state.visualizar_cpf,
          data_nascimento: state.visualizar_dataNascimento,
          sexo: state.visualizar_sexo,
          endereco: state.visualizar_endereco,
          numero: state.visualizar_num,
          bairro: state.visualizar_bairro,
          complemento: state.visualizar_complemento,
          cep: state.visualizar_cep,
          estado: state.visualizar_estado,
          cidade: state.visualizar_cidade,
          telefone: state.visualizar_telefone,
          celular: state.visualizar_zap,
          tel_emergencia: state.visualizar_tel_emergencia,
          email: state.visualizar_email,
          status: state.visualizar_status,
          tipo_pessoa: state.visualizar_tipo_pessoa,
          rg: state.visualizar_rg,
          sempre_aberto: state.visualizar_sempre_aberto,
          cnpj: state.visualizar_cnpj,
          nome_cidade: state.visualizar_nome_cidade,
          nome_estado: state.visualizar_nome_estado,
          observacao: state.visualizar_obs,
        });
        console.log(data);
        //state.isOpen = false;
        state.modo = "visualizar";
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchEstados() {
      try {
        state.isLoading = true;
        const { data } = await services.estados.getAll({
          type: state.currentFeedbackType,
        });
        console.log(data);
        //  console.log(data)
        state.estados = data;
        //   state.pagination = data.pagination

        state.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }

    async function buscarPorNome() {
      if (state.codigo.length > 0) {
        try {
          const { data } = await services.fornecedores.obterPorId({
            token: token,
            empresa_id: empresa_id,
            id: state.codigo,
          });
          var array = [];
          array.push(data);
          state.fornecedores = array;
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          state.isLoading = true;
          const { data } = await services.fornecedores.fornecedoresPorNome({
            type: state.currentClientType,
            token: token,
            empresa_id: empresa_id,
            nome: state.nome_busca,
          });
          console.log(data);
          //  console.log(data)
          state.fornecedores = data;
          state.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      }
    }
    const openOffcanvas = () => {
      // Estado para controlar a visibilidade do offcanvas
      console.log(isVisible.value);
      //offcanvasRef.value.showOffcanvas();
      if (isVisible.value) {
        offcanvasRef.value.hideOffcanvas();
      } else {
        offcanvasRef.value.showOffcanvas();
      }
    };
    return {
      state,
      deletarFornecedor,
      abrirModal,
      fecharModal,
      buscarPorNome,
      editarModo,
      atualizarForncedor,
      SelectEstados,
      fetchCidades,
      openOffcanvas,
      offcanvasRef,
      openOffcanvas,
    };
  },
};
</script>
<style scoped>
.quad_phone {
  width: 100%;
  min-height: 50px;
  border: 1px solid #edf2f6;
  border-radius: 5px;
  margin-bottom: 20px;
}
.quad_phone .linha {
  background-color: #fafbfc;
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  padding: 8px 14px;
  border-bottom: 1px solid #edf2f6;
}
.quad_phone .linha2 {
  background-color: #fff;
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  padding: 8px 14px;
  border-bottom: 1px solid #edf2f6;
}
.quad_phone .linha_last {
  border-bottom: none;
}
.i {
  font-size: 12pt;
}
.quad_phone .linha .titulo,
.quad_phone .linha2 .titulo {
  font-weight: 400;
  color: #1e1a22;
  font-size: 13pt;
}
.quad_phone .linha .texto,
.quad_phone .linha2 .texto {
  font-weight: 100;
  color: #a6a6a6;
}
.btn_lupa {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 10pt;
}
.btn_x {
  padding: 1px 5px;
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 10pt;
}

.persona {
  color: #aede00;
  width: 24px;
  height: 24px;
}

.quad {
  padding: 13px;
  gap: 10px;
  width: 50px;
  height: 50px;
  background: #010d1f;
  border-radius: 12px;
}

.title2 {
  color: #000;
  margin-top: 10px;
}

.title1 {
  color: #000;
  margin-top: 10px;
  font-size: 11pt;
  line-height: 1;
}

.arow {
  margin-right: 5px;
  font-size: 0.7rem !important;
  color: #034aa6;
  font-weight: 700;
}

.text1 {
  color: #034aa6;
  font-size: 7pt !important;
  font-weight: bold;
}

.num {
  margin-top: -15px;
}

.card .table th {
  padding: 15px !important;
}

.btn_busca {
  background-color: #aede00 !important;
  border-color: #aede00 solid 1px !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 15px !important;
  border-color: #aede00 !important;
}

.btn_busca:hover {
  transform: none;
}

.div_btn_buscar {
}

.btn_add_fornecedor:hover {
  transform: none;
}

.div_add_fornecedor {
  text-align: right;
}

input {
  border-color: #b8b8b8;
  border-width: 1px;
  color: #505050;
  border-radius: 5px;
  transition: 0.5s;
}

input:focus {
  border-color: #252525;
}

.btn_cancelar {
  color: #ffff;
  background-color: #788596;
  border: #788596 solid 5px;
}

.btn_cancelar:hover {
  transform: none;
  color: #ffff;
}

.area_obs {
  width: 100%;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
  outline: none;
  height: 100px;
  border: 2px solid #b8b8b8;
  resize: none;
}

.area_obs:focus {
  border-color: #252525;
  outline: none;
}

.space_btn {
  text-align: right;
}

.btn_edit {
  cursor: pointer;
  border: none;
  background: transparent;
}

.btn_salvar {
  color: #ffff;
  background: #034aa6;
  border: #034aa6 solid 5px;
}

.btn_salvar:hover {
  transform: none;
  color: #fff;
}

label {
  color: #000;
  font-weight: 700;
}

select {
  border-color: #b8b8b8;
  border-width: 2px;
  color: #505050;
  border-radius: 15px;
  transition: 0.5s;
}

select:focus {
  border-color: #252525;
}

.btn_add_fornecedor {
  background-color: rgb(3, 74, 166);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(3, 74, 166);
  border-radius: 10px;
  font-size: 10pt;
  padding: 12px;
  font-weight: 700;
  width: 70%;
  margin-bottom: 15px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn_add_fornecedor {
    font-size: 9pt;
    width: 100%;
  }
}

@media (min-width: 1000px) and (max-width: 1400px) {
  .espaco_md {
    padding-left: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .espaco_md {
    padding-left: 8rem;
  }

  .div_add_fornecedor {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .btn_busca {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
  .btn_add_fornecedor {
    width: 100%;
  }
}
</style>
