export default httpClient => ({
  listar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/buscar-pontuacoes/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
  

  obterPorId: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/buscar-pontuacao/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },

    obterParticipantes: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/buscar-pessoas-por-pontuacao/' + empresa_id, {
      token,
      pontuacao_id:id
    }, { headers })
    return { data: response.data }
  },
    
  obterPedidos: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/busca-pedidos-pendentes-pontuacao/' + empresa_id, {
      token,
      pontuacao_id:id
    }, { headers })
    return { data: response.data }
  },

  avaliar: async ({ token, empresa_id, pedido_id, status }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/avaliar-pedido-pontuacao/' + empresa_id, {
      token,
      pedido_id,
      status
    }, { headers })
    return { data: response.data }
  },

  save: async (empresa_id, token, pontuacao) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/salvar-pontuacao/' + empresa_id, pontuacao, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
    update: async (empresa_id, token, pontuacao) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/atualizar-pontuacao/' + empresa_id, pontuacao, { headers })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
    deletar: async ({ token, empresa_id, id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/deletar-pontuacao/' + empresa_id, {
      token,
      id
    }, { headers })
    return { data: response.data }
  },
    
  //Atividades
    
  obterAtividades: async ({ token, empresa_id, pontuacao_id }) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/buscar-atividade-pontuacao/' + empresa_id, {
      pontuacao_id
    }, { headers })
    return { data: response.data }
  },
    
  salvarAtividade: async (empresa_id, token, atividade) => {
    const headers = {
      "Authorization": token
    }
    const response = await httpClient.post('/salvar-atividade-pontuacao/' + empresa_id, atividade, { headers })

    return {
      data: response.data,
    }
  },
});